import experiment from 'experiment-amd'
import clientSpecMapUtils from '../utils/clientSpecMapUtils'
import wixCodeMonitoring from './wixCodeMonitoringWrapper'
import wixCodeServiceFacade from './wixCodeServiceFacade'
import writeableAppService from './writeableAppService'
import hooks from '../../hooks/hooks'
import type {PS} from '@wix/document-services-types'
import {getMetaSiteId} from '../../utils/dalUtil'

function _updateWixCodeModel(ps: PS, newAppResult) {
    const {gridAppId} = newAppResult
    if (experiment.isOpen('specs.WixCodeOpenCodeAppIdEnabled')) {
        ps.dal.set(ps.pointers.wixCode.getOpenWixCodeAppId(), gridAppId)
    } else {
        ps.dal.set(ps.pointers.wixCode.getRevisionGridAppId(), gridAppId)
    }
    hooks.executeHook(hooks.HOOKS.AUTOSAVE.ACTION, null, [ps])
    return newAppResult
}

async function _doProvision(ps: PS, existingWixCodeApp) {
    const options = {
        baseUrl: wixCodeServiceFacade.getBaseUrlFromPS(ps),
        metasiteId: getMetaSiteId(ps),
        signedInstance: existingWixCodeApp ? existingWixCodeApp.instance : null,
        branchId: UAAS,IAEzF,MAAM2moB,EAAkB,CACpBqC,yBAAwB,CAACpC,EAAW12vB,EAAO7uE,IAChC6uE,EAAM2nG,WAAW,GAAD,OAAIsvpB,EAAY,YAAI9l0B,GAAU8l0B,EAAc,CAAC,8BAA+B9l0B,KAI3Gmk0B,EAAAA,QAA0BsB,oCAAoCK,EAAcR,iCCb5E,kBACA,WACA,cACA,cAA2D,mDAE3D,MAAMz/hB,EAAe,aAEfxrS,EAAQlwB,EAAAA,QAAE/D,OAAO,CAAC,EAAGmyM,EAAAA,UAAUgF,WAAYqqpB,EAAAA,SAC3CC,EAAe191B,EAAAA,QAAE/D,OAAO,CAAC,EAAGmyM,EAAAA,UAAUgqQ,qBAAsBqlZ,EAAAA,SAElE,SAASE,IACL,OAAO,CACX,CAEA,SAASC,EAAa1+1B,EAAMs91B,EAAgBxW,EAAe//mB,GACvD,MAAM43nB,EAAUC,EAAW5+1B,GACrBolV,EAAY0hgB,EAAc,CAAC,cAEjC,OAAOhm1B,EAAAA,QAAE/C,OACLqnV,GACA,SAAUy5gB,EAAa3osB,EAAMh7I,GACzB,MAAMgwjB,EAAU4zR,EAAgB9+1B,EAAMkb,EAAQyj1B,GAAS7/1B,OAAOioO,EAAY9rN,IAC1E,OAAK4j1B,GAAe/X,EAAc57Q,GACvBA,EAGJ2zR,CACX,GACA,KAER,CAEA,SAASD,EAAW5+1B,GAChB,MAAM2+1B,EAAUH,EAAax+1B,GAC7B,GAAI2+1B,EACA,OAAOA,EAGX,MAAM,IAAI131B,MAAM,8BAAD,OAA+BjH,GAClD,CAEA,SAAS8+1B,EAAgB9+1B,EAAckb,EAAgByj1B,GACnD,MAAO,CAAC,YAAazj1B,EAAQ,OAAQyj1B,QAAAA,EAAWC,EAAW5+1B,GAC/D,CAGAc,EAAAA,QAAEsM,QAAQ4jB,GAAO,SAAUhxB,GACvB861B,EAAAA,QAA0BkB,oBAAoBh81B,EAAM0+1B,EAAa500B,KAAK,KAAM9pB,GAAOy+1B,GAAe,GAAO,EAC7G,IAEA,MAAMxC,EAAkB,CACnsureAppIsWriteable,
    handleAppIsReadOnlyServerError: writeableAppService.handleAppIsReadOnlyServerError
}
